import React, { useEffect, useState } from "react";
import { Container, Row, Col, CardBody, Card, Table, Button, Modal } from "reactstrap";
import { Link } from "react-router-dom";
import jwt from "jsonwebtoken";
import { useToasts } from "react-toast-notifications";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

//Import Components
import MiniWidget from "./mini-widget";
import SalesAnalyticsChart from "./salesanalytics-chart";
import TopProduct from "./topselling-product";
import TopUser from "./topuser";
import TopHire from "./topHire";
import HiredProduct from "./hired-product";
import RecentActivity from "./recent-activity";
import EcommerceProducts from "../Ecommerce/EcommerceProducts/index";
// import SocialSource from "./socialsource";
import LatestTransaction from "./latest-transaction";

//Import Image
import setupanalytics from "../../assets/images/setup-analytics-amico.svg";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";

//import Skeleton from '@material-ui/core/Skeleton';
import {
  prdTopOrdersProducts,
  prdOrdersSummary,
  prdOrdersGrowth,
  getBusinessTopHire,
  // getBusinessTopOrderedBids,
  getBusinessTopBids,
  getBusinessTopUserOrdersUsers,
  getUser,
  mstCompany,
  getMstPackageList,
  prdOrdersStats,
  getCompanyAuditLog,
  getCompanyTaskAsync,
  UNPAUSE_BUSINESS_PACKAGE,
  upgradeBusinessPackage,
} from "../../services/graphql";

const series1 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 20, 36, 40, 54],
  },
];

const options1 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const series2 = [
  {
    data: [25, 66, 41, 78, 60, 29, 44, 12, 36, 98, 54],
  },
];
const options2 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
    },
  },
};

const series3 = [55];

const options3 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const series4 = [
  {
    data: [25, 66, 41, 89, 63, 25, 44, 12, 36, 9, 54],
  },
];

const options4 = {
  fill: {
    colors: ["#f1b44c"],
  },
  chart: {
    width: 70,
    sparkline: {
      enabled: !0,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
    },
  },
  dataLabels: {
    enabled: !1,
  },
  labels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
  xaxis: {
    crosshairs: {
      width: 1,
    },
  },
  tooltip: {
    fixed: {
      enabled: !1,
    },
    x: {
      show: !1,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: !1,
    },
  },
};

const Dashboard = () => {
  const [prdTopOrdersProductsData, setPrdTopOrdersProductsData] = useState([]);
  const [
    getBusinessTopUserOrdersUsersData,
    setGetBusinessTopUserOrdersUsersData,
  ] = useState([]);
  const [getBusinessTopBidsData, setGetBusinessTopBidsData] = useState([]);
  const [getBusinessTopHireData, setGetBusinessTopHireData] = useState([]);

  const [prdOrdersSummaryData, setPrdOrdersSummaryData] = useState([]);
  const [prdOrdersGrowthData, setPrdOrdersGrowthData] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [ordersStats, setOrdersStats] = useState([]);
  const [auditLogData, setAuditLogData] = useState([]);
  const [taskAsyncData, setTaskAsyncData] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [mainBusinessUser, setMainBusinessUser] = useState(false);
  const [mstCompanyData, setMstCompanyData] = useState({});
  const { addToast } = useToasts();
  const [subscriptionStatus, setSubscriptionStatus] = useState(false);
  const [modal_scroll, setmodal_scroll] = useState(false);

  const [fetchTopOrdersProductsData, { data: topOrdersProducts }] =
    useLazyQuery(prdTopOrdersProducts);
    const [fetchMstCompany, { data: fetchMstCompanyData }] =
    useLazyQuery(mstCompany);

  const [fetchBusinessTopBidsData, { data: businessTopBids }] =
    useLazyQuery(getBusinessTopBids);
  const [fetchBusinessTopHireData, { data: businessTopHire }] =
    useLazyQuery(getBusinessTopHire);

  const [
    fetchBusinessTopUserOrdersUsersData,
    { data: businessTopUserOrdersUsers },
  ] = useLazyQuery(getBusinessTopUserOrdersUsers);

  const [fetchPrdOrdersGrowth, { data: prdOrdersGrowthRecord }] =
    useLazyQuery(prdOrdersGrowth);

  const [fetchPrdOrdersSummary, { data: prdOrdersSummaryRecord }] =
    useLazyQuery(prdOrdersSummary);

  const [fetchGetUser, { data: getUserData }] = useLazyQuery(getUser);
  const [fetchPackageList, { data: packageListData }] =
    useLazyQuery(getMstPackageList);
  const [fetchOrdOrdersStats, { data: prdOrdersStatsData }] =
    useLazyQuery(prdOrdersStats);
  const [fetchCompanyAuditLog, { data: companyAuditLogData }] =
    useLazyQuery(getCompanyAuditLog);
  const [fetchCompanyTaskAsync, { data: companyTaskAsyncData }] =
    useLazyQuery(getCompanyTaskAsync);

    const [
      unPauseSubscription,
      {
        loading: unPauseSubscriptionLoading,
        data: unPauseSubscriptionData,
        error: unPauseSubscriptionError,
      },
    ] = useMutation(UNPAUSE_BUSINESS_PACKAGE);

    useEffect(() => {
      if (unPauseSubscriptionData && unPauseSubscriptionData.unPauseBusinessPackage) {
        if (unPauseSubscriptionData.unPauseBusinessPackage.success === true) {
          setSubscriptionStatus(true)
      
        }
      }
    }, [unPauseSubscriptionData]);
    

  useEffect(() => {
    fetchTopOrdersProductsData();
    fetchBusinessTopUserOrdersUsersData();
    fetchBusinessTopBidsData();
    fetchBusinessTopHireData();
    fetchPrdOrdersGrowth();
    fetchPrdOrdersSummary();
    fetchGetUser();
    fetchOrdOrdersStats();
    fetchCompanyAuditLog({
      variables: {
        page: 1,
        size: 10,
      },
    });
    fetchCompanyTaskAsync({
      variables: {
        page: 1,
        size: 10,
      },
    });

    const token = localStorage.getItem("authToken").replace(/^"|"$/g, "");
    var decodeJwt = jwt.decode(token);

    if (Object.values(decodeJwt)[4] === "Main Business User") {
      setMainBusinessUser(true);
    }
  }, [
    fetchTopOrdersProductsData,
    fetchBusinessTopUserOrdersUsersData,
    fetchBusinessTopBidsData,
    fetchBusinessTopHireData,
    fetchCompanyTaskAsync,
    fetchCompanyAuditLog,
    fetchPrdOrdersSummary,
    fetchPrdOrdersGrowth,
    fetchOrdOrdersStats,
    fetchGetUser,
  ]);

  useEffect(() => {
    if (getUserData && getUserData.getUser) {
      setUserDetails(getUserData.getUser.result);
      console.log(getUserData.getUser.result);
      if (getUserData.getUser.result) {
        fetchPackageList({
          variables: {
            packageIds: null,
            status: true
          },
        });
        fetchMstCompany({
          variables: {
            id: Number(getUserData.getUser.result.companyId),
          },
        })
      }
    }
  }, [getUserData, fetchPackageList]);
  
  useEffect(() => {
    if (fetchMstCompanyData && fetchMstCompanyData.mstCompany) {
      setMstCompanyData(
        fetchMstCompanyData.mstCompany
      );
      if(fetchMstCompanyData.mstCompany.companyStatus.statusName !== 'Paused'){
        setSubscriptionStatus(false);
      }
      
    }
  }, [fetchMstCompanyData]);

  useEffect(() => {
    if (topOrdersProducts && topOrdersProducts.prdTopOrdersProducts) {
      setPrdTopOrdersProductsData(
        topOrdersProducts.prdTopOrdersProducts.result
      );
    }
  }, [topOrdersProducts]);

  useEffect(() => {
    if (
      businessTopUserOrdersUsers &&
      businessTopUserOrdersUsers.getBusinessTopUserOrdersUsers
    ) {
      setGetBusinessTopUserOrdersUsersData(
        businessTopUserOrdersUsers.getBusinessTopUserOrdersUsers.result
      );
    }
  }, [businessTopUserOrdersUsers]);

  useEffect(() => {
    if (businessTopBids && businessTopBids.getBusinessTopBids) {
      setGetBusinessTopBidsData(businessTopBids.getBusinessTopBids.result);
    }
  }, [businessTopBids]);
  useEffect(() => {
    if (businessTopHire && businessTopHire.getBusinessTopHire) {
      setGetBusinessTopHireData(businessTopHire.getBusinessTopHire.result);
    }
  }, [businessTopHire]);

  useEffect(() => {
    if (packageListData && packageListData.getMstPackageList) {
      setPackageList(packageListData.getMstPackageList.result);
    }
  }, [packageListData]);

  useEffect(() => {
    if (prdOrdersGrowthRecord && prdOrdersGrowthRecord.prdOrdersGrowth) {
      setPrdOrdersGrowthData(prdOrdersGrowthRecord.prdOrdersGrowth.result);
    }
  }, [prdOrdersGrowthRecord]);

  useEffect(() => {
    if (prdOrdersSummaryRecord && prdOrdersSummaryRecord.prdOrdersSummary) {
      setPrdOrdersSummaryData(prdOrdersSummaryRecord.prdOrdersSummary.result);
    }
  }, [prdOrdersSummaryRecord]);

  useEffect(() => {
    if (prdOrdersStatsData && prdOrdersStatsData.prdOrdersStats) {
      setOrdersStats(prdOrdersStatsData.prdOrdersStats.result);
    }
  }, [prdOrdersStatsData]);

  useEffect(() => {
    if (companyTaskAsyncData && companyTaskAsyncData.getCompanyTaskAsync) {
      setTaskAsyncData(companyTaskAsyncData.getCompanyTaskAsync.result);
    }
  }, [companyTaskAsyncData]);

  useEffect(() => {
    if (companyAuditLogData && companyAuditLogData.getCompanyAuditLog) {
      setAuditLogData(companyAuditLogData.getCompanyAuditLog.result);
    }
  }, [companyAuditLogData]);

  let salesReport = [];
  prdOrdersSummaryData.forEach((element, index) => {
    salesReport.push({
      id: index,
      icon: "mdi mdi-arrow-up-bold",
      title: element.salesType,
      value: element.totalAmount,
      decimal: 2,
      prefix: "R ",
      suffix: ".00",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "",
      color: "success",
      desc: "since last week",
      series: series4,
      options: options4,
    });
  });
  let salesReport1 = [];
  prdOrdersGrowthData.forEach((element, index) => {
    salesReport1.push({
      id: index,
      icon: "mdi mdi-arrow-up-bold",
      title: element.salesType,
      value: element.totalAmount,
      decimal: 2,
      prefix: element.salesType !== "Total Revenue" ? "+ " : "R ",
      // suffix: element.salesType !== "Total Revenue" ? " " : ".00",
      suffix: element.salesType !== "Total Growth" ? ".00 " : " %",
      charttype: "bar",
      chartheight: 40,
      chartwidth: 70,
      badgeValue: "",
      color: "success",
      desc: "since last week",
      series: series2,
      options: options2,
    });
  });
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  function tog_scroll() {
    setmodal_scroll(!modal_scroll);
    removeBodyCss();
  }
  const [
    updateBusnessPackage,
    {
      loading: updatePackageLoading,
      data: updatePackageData,
      error: updatePackageError,
    },
  ] = useMutation(upgradeBusinessPackage);
  useEffect(() => {
    if (updatePackageData && updatePackageData.upgradeBusinessPackage) {
      if (updatePackageData.upgradeBusinessPackage.success === true) {
        window.location.assign(updatePackageData.upgradeBusinessPackage.result.paymentUrl);
      }
    }
    
  }, [updatePackageError, updatePackageData, updatePackageLoading]);
  const updatePackageByUser = (packageId) => {
    updateBusnessPackage({
      variables: {
        userDto: {
          packageID: Number(packageId),
          domainUrl: "0",
          discount: 5,
        },
      },
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="EzyFind" breadcrumbItem="Dashboard" />
          <Row>
            {mainBusinessUser === true && <MiniWidget reports={salesReport1} />}
          </Row>
          <Row>
            {mainBusinessUser === true && <MiniWidget reports={salesReport} />}
          </Row>

          <Row>
            <Col xl={8}>
              {mainBusinessUser === true && <SalesAnalyticsChart />}
            </Col>

            <Col xl={4}>
              {mainBusinessUser === true && (
                <Card className="bg-primary">
                  <CardBody>
                    <Row className="align-items-center">
                      <Col sm={8}>
                        <p className="text-white font-size-18">
                          Enhance your <b>Campaign</b> for better outreach{" "}
                          <i className="mdi mdi-arrow-right"></i>
                        </p>
                        {userDetails.packageID !== null &&
                        userDetails.packageID === 1 && (
                          <div className="mt-4">
                            <p className="text-white font-size-18">
                          Corporate Subscription{" "}
                          <i className="mdi mdi-arrow-right"></i>
                        </p>
                          </div>
                        )}
                        {(userDetails.packageID !== null &&
                        (userDetails.packageID === 2 || userDetails.packageID === 3 ||  userDetails.packageID === 3)) && (
                          <div className="mt-4">
                            <button
                              type="button"
                              className="btn btn-warning upgrade-4 waves-effect waves-light"
                              onClick={() => {
                                tog_scroll();
                              }}
                              data-toggle="modal"
                            >
                              Upgrade Account
                            </button>
                          </div>
                        )}
                        {(packageList !== null && subscriptionStatus === true && (mstCompanyData && setMstCompanyData.companyStatus.statusName === 'Paused' )) && (
                          
                          <div className="mt-4">
                            <Button
                              onClick={() => unPauseSubscription()}
                              className="mx-2"
                              type="submit"
                              color="dark"
                            >
                              UnPause
                            </Button>
                          </div>
                        )}
                      </Col>
                      <Modal
                        size="xl"
                        isOpen={modal_scroll}
                        toggle={() => {
                          tog_scroll();
                        }}
                        scrollable={true}
                      >
                        <div className="modal-header services" id="services">
                          {/* <h5 className="modal-title mt-0">
                            Select Subscription Plan
                          </h5> */}
                          <h2>SELECT SUBSCRIPTION PLAN</h2>
                          <button
                            type="button"
                            onClick={() => setmodal_scroll(false)}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        {/* <h5 className="bg-soft-dark mx-3">
                          1 MONTH&#160; &#124;
                        </h5>
                        <h5 className="bg-danger mx-3">
                          3 MONTHS (5% OFF)&#160; &#124;
                        </h5>
                        <h5 className="bg-soft-dark mx-3">
                          6 MONTHS (10% OFF)&#160; &#124;
                        </h5>
                        <h5 className="bg-danger mx-3">
                          9 MONTHS (15% OFF)&#160; &#124;
                        </h5> */}
                        <Row>
                          <Col>
                            <div className="table-respons">
                              <Table className="tabled mb-0">
                                <thead>
                                  <tr>
                                    <th className="month-1">1 MONTH</th>
                                    <th className="month-3">3 MONTH (5%OFF)</th>
                                    <th className="month-6">
                                      6 MONTH (10%OFF)
                                    </th>
                                    <th className="month-9">
                                      9 MONTH (15%OFF)
                                    </th>
                                  </tr>
                                </thead>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                        <div className="modal-body">
                          {packageList &&
                            packageList.map((item, index) => (
                              <Row key={index + item.packageName}>
                                <Col>
                                  <Card>
                                    <div className="services__wrapper">
                                      <div className="services__card">
                                        <div className="corporate">
                                          {item.packageName}
                                        </div>
                                        <div className="income">
                                          <p className="income-1">
                                            R {item.fifteenOFF}.00
                                          </p>
                                          <p>
                                            <h3 className="text-light">
                                              R{item.fifteenDiscount}.00
                                            </h3>
                                          </p>
                                          <p className="p3">PER MONTH</p>
                                        </div>
                                        <div className="service-feature">
                                          <p>2 HRS TIME DELAY</p>
                                          <p>UNLIMITED USERS</p>
                                          <p>UNLIMITED CATEGORY</p>
                                          <p>300 REQUESTS</p>
                                          <p>50 SPECIAL</p>
                                          <p>50 E-FLYERS</p>
                                          <p>UNLIMITED SALESLEAD</p>
                                          <p>100 KEYWORDS</p>
                                          <p>CRM</p>
                                          <p>PAYFAST INTEGRATION</p>
                                        </div>
                                        <div className="services__btn">
                                          <button>
                                            {" "}
                                            <Link
                                              to="#"
                                              onClick={() =>
                                                updatePackageByUser(
                                                  item.packageID
                                                )
                                              }
                                              // className="services__btn"
                                            >
                                              Subscribe
                                            </Link>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </Card>
                                </Col>
                                <Col>
                                  <Card></Card>
                                </Col>
                              </Row>
                            ))}
                        </div>
                      </Modal>
                      <Col sm={4}>
                        <div className="mt-4 mt-sm-0">
                          <img
                            src={setupanalytics}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
              {mainBusinessUser === true && (
                <TopProduct topProduct={prdTopOrdersProductsData} />
              )}
            </Col>
          </Row>
          <Row>
            <Col xl={4}>
              <TopUser topUser={getBusinessTopBidsData} />
            </Col>
            <Col xl={4}>
              <TopHire topHire={getBusinessTopHireData} />
            </Col>
            <Col xl={4}>
              <HiredProduct hiredProduct={getBusinessTopUserOrdersUsersData} />
            </Col>
            <Col xl={4}>
              <RecentActivity auditLogData={auditLogData} />
              {/* <SocialSource /> */}
            </Col>
          </Row>
          <LatestTransaction />
          <EcommerceProducts addToast={addToast} />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
