import gql from 'graphql-tag';

/* get getMstCategoryMain query */
const getPrdScope = gql`
  query {
    getPrdScope{ 
      count,
      currentPage,
      message,
      nextPage,
      prevPage,
      success,
      totalPages,
      result{
        scopeId,
        scopeName
      }
    }
    
  }
`;

export default getPrdScope;
