import gql from 'graphql-tag';

/* get getMstCategoryMain query */
const mstCategorys = gql`
  query {
    mstCategorys{
         categoryIcon,
         categoryId,
         categoryName,
         categoryThumbNailIcon,
         createdBy,
         createdDate,
         isActive,
         isCategory,
         isMainCategory,
         isMenuAllowed,
         modifiedBy,
         modifiedDate,
         parentCategoryId,
         timeDelayException
       
     }
   }
`;

export default mstCategorys;
