import gql from 'graphql-tag';

/* createMstFavourites mutation */
const createMstCrmProduct = gql`
  mutation createPrdProducts(
    $prdProducts: PrdProductsInputType!
    
  ) {
    createPrdProducts(
        prdProducts: $prdProducts
    ) {
      count,
      currentPage,    
      message,
      nextPage,
      prevPage,
      success,
      totalPages,
      result{      
        categoryId,     
        description,
        documentName,
        documentPath,
        isActive,      
        productId,
        productImage,
        productName,
        productNumber,
        salesTypeId,
        typeId,
        inventory, 
        unitCost,
        length, 
        width, 
        height, 
        volume, 
        weight, 
        googleSchema,
        domainCategory, 
        startDate,
        endDate,
        companyID
      }
    }
   
  }
`;

export default createMstCrmProduct;