import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import httpLink from "../../Api/http";
import {
  Card,
  Col,
  Container,
  Form,
  Button,
  Input,
  Label,
  Row,
  Collapse
} from "reactstrap";
import Select from "react-select";
import jwt from 'jsonwebtoken';
import Dropzone from "react-dropzone";
import { useToasts } from "react-toast-notifications";
import DatePicker from "react-datepicker";
import { useMutation, useLazyQuery, empty } from "@apollo/react-hooks";
import {
  createMstCrmProduct,
  mstCategorys,
  getPrdSalesType,
  getPrdType,
  getPrdScope,
  getMstCategoryByParentId,
} from "../../services/graphql";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const EcommerceAddProduct = () => {
  const [vaildStartDate, setVaildStartDate] = useState(false);
  const [startDate, setStartDate] = useState([{}]);
  const [endDate, setEndDate] = useState([{}]);
  const [vaildEndDate, setVaildEndDate] = useState(false);
  const [selectedFiles, setselectedFiles] = useState([]);
  const [tempfiles, setTempFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [pName, setpName] = useState(""); 
  const [pPrice, setpPrice] = useState("");
  const [pLength, setpLength] = useState("");
  const [pWidth, setpWidth] = useState("");
  const [pHeight, setpHeight] = useState("");
  const [pVolume, setpVolume] = useState("");
  const [pWeight, setpWeight] = useState("");
  const [pManufacturer, setpManufacturer] = useState("");
  const [pMBrand, setpMBrand] = useState("");
  const [pScope, setpScope] = useState("");
  const [pCat, setpCat] = useState("");
  const [pActive, setpActive] = useState(true);
  const [pSales, setpSales] = useState("");
  const [pType, setpType] = useState("");
  const [pDescription, setpDescription] = useState("");
  const [page, setPage] = useState(1);
  const [activeCategory, setActiveCategory] = useState("");
  const [categoryData, setCategoryData] = useState({
    parentId: null,
    categoryId: null,
  });
  /* Category Data */
  const [fetchCategoryMain, { data: categoryMainData }] =
    useLazyQuery(mstCategorys);

  const [fetchPrdSales, { data: prdSalesData }] =
    useLazyQuery(getPrdSalesType);

  const [fetchPrdTypes, { data: prdTypesData }] =
    useLazyQuery(getPrdType);

  const [fetchPrdScopes, { data: prdScopesData }] =
    useLazyQuery(getPrdScope);

  const [fetchSubCategoryMain, { data: subCategoryMainData }] = useLazyQuery(
      getMstCategoryByParentId,
      {
        fetchPolicy: "network-only",
      }
  );
    const cache = new InMemoryCache({
      addTypename: false,
    });
    const client = new ApolloClient({
      link: httpLink,
      cache,
    });
  const [categoryMain, setCategoryMain] = useState([]);
  const [prdsalesdata, setprdData] = useState([]);
  const [prdtypesdata, setprdType] = useState([]);
  const [prdscopesdata, setprdScope] = useState([]);
  const [subCategoryMain, setSubCategoryMain] = useState([]);
  useEffect(() => {
    //console.log(categoryMainData);
    if (categoryMainData && categoryMainData.mstCategorys) {
      const catMainData = categoryMainData.mstCategorys || [];
      //console.log(catMainData);
      setCategoryMain(catMainData);
    }
  }, [categoryMainData]);

  useEffect(() => {
    if (prdSalesData && prdSalesData.getPrdSalesType) {
      const prdData = prdSalesData.getPrdSalesType.result || [];
      setprdData(prdData);
    }
  }, [prdSalesData]);

  useEffect(() => {
    if (prdTypesData && prdTypesData.getPrdType) {
      const prdType = prdTypesData.getPrdType.result || [];
      setprdType(prdType);
    }
  }, [prdTypesData]);

  useEffect(() => {
    if (prdScopesData && prdScopesData.getPrdScope) {
      const prdScope = prdScopesData.getPrdScope.result || [];
      setprdScope(prdScope);
    }
  }, [prdScopesData]);

  let catlist = [];
  useEffect(() => {
    const fetchSubCategory = async (status) => {
      const data = await client
        .query({
          query: getMstCategoryByParentId,
          variables: { id: status },
        })
        .then((result) => result.data.getMstCategoryByParentId.result);
      catlist.push({ id: status, data: data });
      setSubCategoryMain(catlist);
    };

    (async () => {
      Promise.all(
        categoryMain.map((status) => {
          return fetchSubCategory(status.categoryId);
        })
      );
    })();
  }, [categoryMain, fetchSubCategoryMain]);

  useEffect(() => {
    if (subCategoryMainData && subCategoryMainData.getMstCategoryByParentId) {
      const data = subCategoryMainData.getMstCategoryByParentId.result;
      const tempData = subCategoryMain;
      const categoryId = data.categoryId;
      tempData.push({ data });
      setSubCategoryMain(data);
    }
  }, [subCategoryMainData]);

  useEffect(() => {
    fetchCategoryMain();
  }, [fetchCategoryMain]);

  useEffect(() => {
    fetchPrdSales();
  }, [fetchPrdSales]);

  useEffect(() => {
    fetchPrdTypes();
  }, [fetchPrdTypes]);

  useEffect(() => {
    fetchPrdScopes();
  }, [fetchPrdScopes]);

  const toggle = () => setIsOpen(!isOpen);

  const [isOpenAddproduct, setIsOpenAddproduct] = useState(false);

  const toggleAddproduct = () => setIsOpenAddproduct(!isOpenAddproduct);

  const [isOpenMetadata, setIsOpenMetadata] = useState(false);

  const toggleMetadata = () => setIsOpenMetadata(!isOpenMetadata);
  const { addToast } = useToasts();
  const options = [
    { value: "AK", label: "Alaska" },
    { value: "HI", label: "Hawaii" },
    { value: "CA", label: "California" },
    { value: "NV", label: "Nevada" },
    { value: "OR", label: "Oregon" },
    { value: "WA", label: "Washington" }
  ];
  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    );
    setselectedFiles(files);
    setTempFiles((tempfiles)=>[...tempfiles, files[0]]);
  }
  const [
    AddProductData,
    { data: addProductData }, 
  ] = useMutation(createMstCrmProduct);

  useEffect(() => {
    if (addProductData && addProductData.createPrdProducts) {
      if (addProductData.createPrdProducts !== true) {
        addToast("Product Added Successfully", {
          appearance: "success",
          autoDismiss: false,
        });
        setTimeout(function(){
          window.location.reload();
        },2000);
      }
    }
  }, [addProductData]);


  const productDataSubmit = () => {
    let finalSDate;
    let finalEDate;
    if(startDate.length < 2){
      finalSDate = new Date();
    }else{
      finalSDate = startDate[startDate.length - 1].date;
      finalSDate = new Date(finalSDate.setDate(finalSDate.getDate() + 1));
    }
    if(endDate.length < 2){
      finalEDate = new Date();
    }else{
      finalEDate = endDate[endDate.length - 1].date;
      finalEDate = new Date(finalEDate.setDate(finalEDate.getDate() + 1));
    }
    if (pName === "") {
      addToast("Product Name should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pPrice === "") {
      addToast("Unit Cost Price should not empty", { appearance: "error", autoDismiss: true });
      return;
    }
    if (pManufacturer === "") {
      addToast("Product Number should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pMBrand === "") {
      addToast("Inventory should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pLength === "") {
      addToast("Length should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pWidth === "") {
      addToast("Width should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pHeight === "") {
      addToast("Height should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pVolume === "") {
      addToast("Volume should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pWeight === "") {
      addToast("Weight should not empty", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if(finalEDate.getTime() <= finalSDate.getTime()){
      addToast("End Date should be greater than Start Date", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pType === "") {
      addToast("Please Select Product Type", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pSales === "") {
      addToast("Please Select Product Sales Type", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pScope === "") {
      addToast("Please Select Product Scope", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (pCat === "") {
      addToast("Please Select Category", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if(tempfiles.length == 0){
      let tempfiles = null;
    }
    //console.log(tempfiles);
   // return;
    const token = localStorage.getItem('authToken').replace(/^"|"$/g, '');
    var decodeJwt = jwt.decode(token);
    AddProductData({
      variables: {
        prdProducts: {
          productId:0,
          productImage: null,
          productName: pName,
          productNumber: pManufacturer,        
          categoryId: parseInt(pCat),        
          description: pDescription,
          documentName: "New Product update 1.docx",
          documentPath: null,
          isActive: pActive,        
          salesTypeId: pSales,
          typeId: pType,
          scopeId:pScope,
          inventory: parseInt(pMBrand),       
          unitCost: parseFloat(parseInt(pPrice).toFixed(1)),
          length: parseFloat(parseInt(pLength).toFixed(2)),
          width: parseFloat(parseInt(pWidth).toFixed(2)),
          height: parseFloat(parseInt(pHeight).toFixed(2)),
          volume: parseFloat(parseInt(pVolume).toFixed(2)),
          weight: parseFloat(parseInt(pWeight).toFixed(2)),
          googleSchema: null,
          domainCategory: 1447,
          startDate: finalSDate.toISOString().split("T")[0] + "T00:00:00.000Z",
          endDate: finalEDate.toISOString().split("T")[0] + "T00:00:00.000Z",
          companyID: parseInt(decodeJwt.companyId)
        },
      },
    });
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Ecommerce" breadcrumbItem="Add Product" />

          <Row>
            <Col lg="12">
              <div id="addproduct-accordion" className="custom-accordion">
                <Card>
                  <Link to="#" onClick={toggle} className="text-dark">
                    <div className="p-4">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar-xs">
                            <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                              01
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Billing Info</h5>
                          <p className="text-muted text-truncate mb-0">Fill all information below</p>
                        </div>
                        <div className="flex-shrink-0">
                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                        </div>
                      </div>

                    </div>
                  </Link>
                  <Collapse isOpen={isOpen}>
                    <div className="p-4 border-top">
                      <Form>
                        <div className="mb-3">
                          <Label htmlFor="productname">Product Name</Label>
                          <Input
                            id="productname"
                            name="productname"
                            type="text"
                            onChange={(e) => setpName(e.target.value)}
                            value={pName}
                            className="form-control"
                          />
                        </div>
                        <Row>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="manufacturername">
                                Product Number
                              </Label>
                              <Input
                                id="manufacturername"
                                name="manufacturername"
                                onChange={(e) => setpManufacturer(e.target.value)}
                                value={pManufacturer}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="manufacturerbrand">
                              Inventory
                              </Label>
                              <Input
                                id="manufacturerbrand"
                                name="manufacturerbrand"
                                onChange={(e) => setpMBrand(e.target.value)}
                                value={pMBrand}
                                type="text"
                                className="form-control"
                              />
                            </div>
                          </Col>

                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="price">Unit Cost</Label>
                              <Input
                                id="price"
                                name="price"
                                type="text"
                                onChange={(e) => setpPrice(e.target.value)}
                                value={pPrice}
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="length">Length</Label>
                              <Input
                                id="length"
                                name="lenth"
                                type="text"
                                onChange={(e) => setpLength(e.target.value)}
                                value={pLength}
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="width">Width</Label>
                              <Input
                                id="width"
                                name="width"
                                type="text"
                                onChange={(e) => setpWidth(e.target.value)}
                                value={pWidth}
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="height">Height</Label>
                              <Input
                                id="height"
                                name="height"
                                type="text"
                                onChange={(e) => setpHeight(e.target.value)}
                                value={pHeight}
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="volume">Volume</Label>
                              <Input
                                id="volume"
                                name="volume"
                                type="text"
                                onChange={(e) => setpVolume(e.target.value)}
                                value={pVolume}
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col lg="4">
                            <div className="mb-3">
                              <Label htmlFor="weight">Weight</Label>
                              <Input
                                id="weight"
                                name="weight"
                                type="text"
                                onChange={(e) => setpWeight(e.target.value)}
                                value={pWeight}
                                className="form-control"
                              />
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="control-label">Enable/Disable Product</Label>
                              <select className="form-control select2" onChange={(e) => setpActive(e.target.value)}
                              value={pActive}>
                                <option key="1" value="true">Enable</option>
                                <option key="2" value="false">Disable</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                        <Col md="4">
                            <div className="mb-3">
                              <Label className="control-label">Sales Type</Label>
                              <select className="form-control select2" onChange={(e) => setpSales(e.target.value)}
                              value={pSales}>
                                <option value="">Select Sales Type </option>
                                {prdsalesdata.map((item, index) => (
                                <option key={item.salesTypeID} value={item.salesTypeID}>{item.salesTypeName}</option>))}
                              </select>
                            </div>
                          </Col>
                          <Col md="4">
                            <div className="mb-3">
                              <Label className="control-label">Product Type</Label>
                              <select className="form-control select2" onChange={(e) => setpType(e.target.value)}
                              value={pType}>
                                <option value="">Select Product Type</option>
                                {prdtypesdata.map((item, index) => (
                                <option key={item.typeID} value={item.typeID}>{item.typeName}</option>))}
                              </select>
                            </div>
                          </Col>
                          <Col md="2" className="date_picker">
                          <Label className="control-label">Select Start Date</Label>
                                        <DatePicker
                                          className="date__pick"
                                          placeholderText="Start Date"
                                          onChange={(date) => {
                                            setVaildStartDate(false);
                                            setStartDate([
                                              ...startDate,
                                              {
                                                date: date,
                                              },
                                            ]);
                                          }}
                                          popperClassName="hire-date-range"
                                          popperPlacement="top-end"
                                          minDate={new Date()}
                                        />
                                        </Col>
                                        <Col md="2" className="date_picker">
                                        <Label className="control-label">Select End Date</Label>
                                        <DatePicker
                                          className="date__pick"
                                          placeholderText="End Date"
                                          onChange={(date) => {
                                            setVaildEndDate(false);
                                            setEndDate([
                                              ...endDate,
                                              {
                                                date: date,
                                              },
                                            ]);
                                          }}
                                          popperClassName="hire-date-range"
                                          popperPlacement="top-end"
                                          minDate={
                                             new Date()
                                          }
                                        />
                                        {vaildEndDate && (
                                          <span className="text-primary text-vaild">
                                            Enter End Date
                                          </span>
                                        )}
                                      </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="control-label">Category</Label>
                              <select className="form-control select2" onChange={(e) => setpCat(e.target.value)}
                              value={pCat}>
                                <option value="">Select Category</option>
                                {categoryMain.map((item, index) => (
                                <option key={item.categoryId} value={item.categoryId}>{item.categoryName}</option>))}
                               
                              </select>
                            </div>
                          </Col>
                          <Col md="6">
                            <div className="mb-3">
                              <Label className="control-label">Product Scope</Label>
                              <select className="form-control select2" onChange={(e) => setpScope(e.target.value)}
                              value={pScope}>
                                <option value="">Select Product Scope</option>
                                {prdscopesdata.map((item, index) => (
                                <option key={index} value={item.scopeId}>{item.scopeName}</option>))}
                              </select>
                            </div>
                          </Col>
                          {/* <Col md="6">
                            <div className="mb-3">
                              <Label className="control-label">Enable/Disable Product</Label>
                              <Select
                                classNamePrefix="select2-selection"
                                placeholder="Choose..."
                                title="Country"
                                options={options}
                                isMulti
                              />
                            </div>
                          </Col> */}
                        </Row>
                        <div className="mb-0">
                          <Label htmlFor="productdesc">
                            Product Description
                          </Label>
                          <textarea
                            className="form-control"
                            id="productdesc"
                            name="productdesc"
                            onChange={(e) => setpDescription(e.target.value)}
                            value={pDescription}
                            rows="4"
                          />
                        </div>

                      </Form>
                    </div>
                  </Collapse>
                </Card>
                <Card>
                  <Link to="#" className="text-dark collapsed" onClick={toggleAddproduct}>
                    <div className="p-4">

                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0 me-3">
                          <div className="avatar-xs">
                            <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                              02
                            </div>
                          </div>
                        </div>
                        <div className="flex-grow-1 overflow-hidden">
                          <h5 className="font-size-16 mb-1">Product Image</h5>
                          <p className="text-muted text-truncate mb-0">Fill all information below</p>
                        </div>
                        <div className="flex-shrink-0">
                          <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                        </div>
                      </div>

                    </div>
                  </Link>
                  <Collapse isOpen={isOpenAddproduct}>
                    <div className="p-4 border-top">
                      <Form>
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="dz-message needsclick">
                                  <div className="mb-3">
                                    <i className="display-4 text-muted uil uil-cloud-upload"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div className="dropzone-previews mt-3" id="file-previews">
                          {tempfiles.map((f, i) => {
                            return (
                              <Card
                                className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                key={i + "-file"}
                              >
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <img
                                        data-dz-thumbnail=""
                                        height="80"
                                        className="avatar-sm rounded bg-light"
                                        alt={f.name}
                                        src={f.preview}
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {f.name}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{f.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            );
                          })}
                        </div>
                      </Form>
                    </div>
                  </Collapse>
                </Card>
              </div>

              <Card>
                <Link to="#" className="text-dark collapsed" onClick={toggleMetadata}>
                  <div className="p-4">

                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <div className="avatar-title rounded-circle bg-soft-primary text-primary">
                            03
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1 overflow-hidden">
                        <h5 className="font-size-16 mb-1">Meta Data</h5>
                        <p className="text-muted text-truncate mb-0">Fill all information below</p>
                      </div>
                      <div className="flex-shrink-0">
                        <i className="mdi mdi-chevron-up accor-down-icon font-size-24"></i>
                      </div>
                    </div>
                  </div>
                </Link>
                <Collapse isOpen={isOpenMetadata}>
                  <div className="p-4 border-top">
                    <Form>
                      <Row>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label htmlFor="metatitle">Meta title</Label>
                            <Input
                              id="metatitle"
                              name="productname"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </Col>
                        <Col sm={6}>
                          <div className="mb-3">
                            <Label htmlFor="metakeywords">Meta Keywords</Label>
                            <Input
                              id="metakeywords"
                              name="manufacturername"
                              type="text"
                              className="form-control"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-0">
                        <Label htmlFor="metadescription">
                          Meta Description
                        </Label>
                        <textarea
                          className="form-control"
                          id="metadescription"
                          rows="5"
                        />
                      </div>
                    </Form>

                  </div>
                </Collapse>
              </Card>
            </Col>
          </Row>
          <Row className="row mb-4">
            <div className="col text-end">
              <Link to="#" className="btn btn-danger edit"> <i className="uil uil-times me-1"></i> Cancel </Link>{" "}
              <Button
                      color="primary"
                      type="submit"
                      onClick={() => productDataSubmit()}
                    >
                      <i className="bx bx-save"></i>Save
                    </Button>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EcommerceAddProduct;
