import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { Row, Col, Alert, Container, CardBody, Card } from "reactstrap";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

//Social Media Imports
import GoogleLogin from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, apiError, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/ezyfind-logo-dark.png";
import logolight from "../../assets/images/ezyfindlogo-light.png";

//Import config
import { facebook, google } from "../../config";
import { useLazyQuery } from "@apollo/react-hooks";
import { SIGN_IN, SOCIAL_LOGIN } from "../../services/graphql";

const Login = (props) => {
  // handleValidSubmit
  const [fetchloginDetail, { data: loginData, error: loginError }] =
    useLazyQuery(SIGN_IN, {
      fetchPolicy: "network-only",
    });
  const [
    fetchSocialLoginDetail,
    { data: socialLoginData, error: socialLoginError },
  ] = useLazyQuery(SIGN_IN, {
    fetchPolicy: "network-only",
  });
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleValidSubmit = (event, values) => {
    let data = values.email + ":" + values.password;
    let buff = new Buffer(data);
    let base64data = buff.toString("base64");
    fetchloginDetail({
      variables: { jti: "hhhyyyyyy" },
      context: { headers: { Authorization: `Basic ${base64data}` } },
    });
  };

//paramdata
const queryParameters = new URLSearchParams(window.location.search)
const parame_token = queryParameters.get("token")
const param_value = JSON.parse(queryParameters.get("value"))
 
if (parame_token!== null) {
  delete param_value.__typename
  console.log("token"+parame_token)
  console.log( param_value)
  localStorage.setItem("authUser", JSON.stringify(param_value));
  localStorage.setItem("authToken", JSON.stringify(parame_token));
  props.loginUser(param_value, props.history);
  
}

  const loginSuccess = (data) => {
    if (data.success === true) {
      localStorage.setItem("authUser", JSON.stringify(data.result));
      localStorage.setItem("authToken", JSON.stringify(data.result.token));
      props.loginUser(data.result, props.history);
    } else {
      props.apiError(data.message, props.history);
    }
  };
  const socialLoginSuccess = (data) => {
    if (data.success === true) {
      localStorage.setItem("authUser", JSON.stringify(data.result));
      localStorage.setItem("authToken", JSON.stringify(data.result.token));
      socialLogin(data.result, props.history, "google");
    } else {
      props.apiError(data.message, props.history);
    }
  };

  useEffect(() => {
    if (loginData && loginData.sSOLogin) {
      loginSuccess(loginData.sSOLogin);
    }
  }, [loginData]);

  useEffect(() => {
    if (socialLoginData && socialLoginData.oAuth) {
      socialLoginSuccess(socialLoginData.oAuth);
    }
  }, [socialLoginData]);

  const signIn = (res, type) => {
    const { socialLogin } = props;
    if (type === "google" && res) {
      // let data = values.email+':'+values.password;
      // let buff = new Buffer(data);
      // let base64data = buff.toString('base64');
      fetchSocialLoginDetail({
        context: {
          headers: { Authorization: `Basic MTEzNDYzMTEyOTg4OTkxMToz` },
        },
      });
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      socialLogin(postData, props.history, type);
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  //handleTwitterLoginResponse
  // const twitterResponse = e => {}

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };

  useEffect(() => {
    document.body.className = "authentication-bg";
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = "";
    };
  });

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="mdi mdi-home-variant h2"></i>
        </Link>
      </div>
      <div className="account-pages my-1 pt-sm-5">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="text-center">
                <Link to="/" className="mb-5 d-block auth-logo">
                  <img
                    src={logo}
                    alt=""
                    height="70"
                    width="250"
                    className="logo logo-dark"
                  />
                  <img
                    src={logolight}
                    alt=""
                    height="70"
                    width="250"
                    className="logo logo-light"
                  />
                </Link>
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card>
                <CardBody className="p-4">
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome Back !</h5>
                    <p className="text-muted">
                      Sign in to continue to EzyFind Portal.
                    </p>
                  </div>
                  <div className="p-2 mt-4">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && typeof props.error === "string" ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <div className="float-end">
                          <Link to="/forgot-password" className="text-muted">
                            Forgot password?
                          </Link>
                        </div>
                        <AvField
                          name="password"
                          label="Password"
                          onChange={(e) => setPassword(e.target.value)}
                          value={password}
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="customControlInline"
                        />
                        <label
                          className="form-check-label"
                          htmlFor="customControlInline"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="mt-3">
                        <button
                          className="btn btn-primary w-100 waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={2884482961826696}
                              autoLoad={true}
                              callback={facebookResponse}
                              render={(renderProps) => (
                                <Link
                                  to="/dashboard"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          {/*<li className="list-inline-item">*/}
                          {/*  <TwitterLogin*/}
                          {/*    loginUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter"*/}
                          {/*    }*/}
                          {/*    onSuccess={this.twitterResponse}*/}
                          {/*    onFailure={this.onFailure}*/}
                          {/*    requestTokenUrl={*/}
                          {/*      "http://localhost:4000/api/v1/auth/twitter/revers"*/}
                          {/*    }*/}
                          {/*    showIcon={false}*/}
                          {/*    tag={"div"}*/}
                          {/*  >*/}
                          {/*    <a*/}
                          {/*      href=""*/}
                          {/*      className="social-list-item bg-info text-white border-info"*/}
                          {/*    >*/}
                          {/*      <i className="mdi mdi-twitter"/>*/}
                          {/*    </a>*/}
                          {/*  </TwitterLogin>*/}
                          {/*</li>*/}
                          <li className="list-inline-item">
                            <GoogleLogin
                              clientId={954978157386}
                              render={(renderProps) => (
                                <Link
                                  to="/dashboard"
                                  className="social-list-item bg-danger text-white border-danger"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-google" />
                                </Link>
                              )}
                              onSuccess={googleResponse}
                              onFailure={googleResponse}
                            />
                          </li>
                        </ul>
                      </div>

                      <div className="mt-4 text-center">
                        <p className="mb-0">
                          Don't have an account ?{" "}
                          <a
                            href="/register"
                            className="fw-medium text-primary"
                          >
                            {" "}
                            Signup now{" "}
                          </a>{" "}
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} EzyFind. Crafted with{" "}
                  <i className="mdi mdi-heart text-danger"></i> by Innovation Evolved Pty Ltd
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStateToProps, { loginUser, apiError, socialLogin })(Login)
);

Login.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
};
