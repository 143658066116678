import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Table,
  CardTitle,
  Label,
  Input,
  Row,
  Col,
  Button,
  Spinner,
} from "reactstrap";
import { isEmpty, map } from "lodash";
import { Link } from "react-router-dom";

import "react-perfect-scrollbar/dist/css/styles.css";
import { PRODUCT_IMAGE_URL } from "../../common/config";
import { useLazyQuery } from "@apollo/react-hooks";
import { prdOrderPayments } from "../../services/graphql";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import img2 from "../../assets/images/small/img-2.jpg";

const LatestTransaction = (props) => {
  const [transactionList, setTransactionList] = useState([]);
  const [
    fetchTransactionList,
    { loading: TransactionLoading, data: transactionListData },
  ] = useLazyQuery(prdOrderPayments);

  useEffect(() => {
    fetchTransactionList({
      variables: {
        key: 2,
        keyType: 1,
        page: 1,
        size: 10,
      },
    });
  }, [fetchTransactionList]);

  useEffect(() => {
    if (transactionListData && transactionListData.prdOrderPayments) {
      setTransactionList(transactionListData.prdOrderPayments.data);
    }
  }, [transactionListData]);

  const rowData = [
    {
      amount: "R100",
      createdBy: "krivahn",
      createdDate: "10/12/2022",
      orderId: "34",
      orderPaymentId: "32",
      paymentDate: "12/12/2022",
      paymentModeId: "23",
      paymentRefNo: "21",
      status: "	Active",
    },
  ];

  let finalList = [];
  const makeDataTransactionList = (TransactionListList) => {
    TransactionListList.forEach((datum) => {
      finalList.push({
        amount: datum.amount,
        createdBy: datum.createdBy,
        createdDate: datum.createdDate,
        orderId: datum.orderId,
        orderPaymentId: datum.orderPaymentId,
        paymentDate: datum.paymentDate,
        paymentModeId: datum.paymentModeId,
        paymentRefNo: datum.paymentRefNo,
        status: datum.isActive === true ? "Active" : "InActive",
      });
    });
    return finalList;
  };

  let TransactionList =
    transactionList.length === 0
      ? rowData
      : makeDataTransactionList(transactionList);

  const data = {
    rows: TransactionList,
  };

  return (
    <Row>
      <Col lg={12}>
        <Card>
          <CardBody>
            <CardTitle className="h4 mb-4">Latest Transaction</CardTitle>
            <div className="table-responsive">
              <Table className="table-centered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Order ID</th>
                    <th>Billing No.</th>
                    <th>Date</th>
                    <th>Total</th>
                    <th>Payment Status</th>
                    <th>Payment Method</th>
                    <th>View Details</th>
                  </tr>
                </thead>
                {isEmpty(transactionList) && TransactionLoading && (
                  <Row>
                    <Col>
                      <Card>
                        <Row className="g-0 align-items-center">
                          <Col md={12}>
                            <CardBody>
                              <Spinner className="m-1" color="primary" />
                            </CardBody>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                )}

                {!isEmpty(transactionList) &&
                  transactionList.map((data, key) => (
                    <tbody key={"kk" + key}>
                      <tr>
                        <td>
                          <Link>{data.orderId}</Link>
                        </td>
                        <td>{data.paymentRefNo}</td>
                        <td>{data.paymentDate}</td>
                        <td>{data.amount}</td>
                        <td>
                          <span className="badge rounded-pill bg-soft-success font-size-12">
                            Paid
                          </span>
                        </td>
                        <td>
                          <i className="fab fa-cc-mastercard me-1"></i>{" "}
                          {data.paymentModeId}
                        </td>
                        <td>
                          <Link
                            to="/invoices-detail"
                            type="button"
                            color="primary"
                            className="btn-sm btn-rounded waves-effect waves-light"
                          >
                            View Details
                          </Link>
                        </td>
                      </tr>

                      {/* <tr>
                    <td>
                      <div className="form-check font-size-16">
                        <Input
                          type="checkbox"
                          className="form-check-input"
                          id="customCheck3"
                        />
                        <Label className="form-check-label" for="customCheck3">
                          &nbsp;
                        </Label>
                      </div>
                    </td>
                    <td>
                      <Link to="#" className="text-body fw-bold">
                        #MB2541
                      </Link>{" "}
                    </td>
                    <td>Jamal Burnett</td>
                    <td>07 Oct, 2019</td>
                    <td>$380</td>
                    <td>
                      <span className="badge rounded-pill bg-soft-danger font-size-12">
                        Chargeback
                      </span>
                    </td>
                    <td>
                      <i className="fab fa-cc-visa me-1"></i> Visa
                    </td>
                    <td>
                      <Link
                        to="/invoices-detail"
                        type="button"
                        color="primary"
                        className="btn-sm btn-rounded waves-effect waves-light"
                      >
                        View Details
                      </Link>
                    </td>
                  </tr> */}
                    </tbody>
                  ))}
              </Table>
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default LatestTransaction;
