import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Container, Row, Col, CardBody, Card } from 'reactstrap';
import jwt from 'jsonwebtoken';

// //Import Scrollbar
import { useLazyQuery } from '@apollo/client';

import SimpleBar from 'simplebar-react';
import { isEmpty, map } from 'lodash';
// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getMstPackageList, getUser } from '../../services/graphql';
//i18n
import { withTranslation } from 'react-i18next';
import Slider from 'react-rangeslider';

const SidebarContent = (props) => {
  const ref = useRef();
  const [def, setdef] = useState(15);
  const [compPercent, setcompPercent] = useState('');
  const [userProfileData, setUserProfileData] = useState({});
  const [fetchGetUser, { data: getUserData }] = useLazyQuery(getUser);
  const [mainBusinessUser, setMainBusinessUser] = useState(false);
  const [individualUser, setIndividualUser] = useState(false);

  const [userDetails, setUserDetails] = useState({});

  const [fetchPackageDetail, { data: packageDetailData }] =
    useLazyQuery(getMstPackageList);
  const [fetchPackageList, { data: packageListData }] =
    useLazyQuery(getMstPackageList);

  const activateParentDropdown = useCallback((item) => {
    item.classList.add('active');
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== 'side-menu') {
      parent2El.classList.add('mm-show');
    }

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show'); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add('mm-show'); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add('mm-show'); // li
              parent5.childNodes[0].classList.add('mm-active'); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('authToken').replace(/^"|"$/g, '');
    var decodeJwt = jwt.decode(token);
    console.log('decodeJwt: ', decodeJwt);

    if (Object.values(decodeJwt)[4] === 'Main Business User') {
      setMainBusinessUser(true);
    }
    if (Object.values(decodeJwt)[4] === 'Individual(Seeker)') {
      setIndividualUser(true);
    }
    fetchGetUser();
    fetchPackageList({
      variables: {
        packageIds: null,
        status: true,
      },
    });
  }, [fetchGetUser, fetchPackageList]);

  useEffect(() => {
    if (getUserData && getUserData.getUser) {
      setUserDetails(getUserData.getUser.result);
      if (getUserData.getUser.result) {
        fetchPackageDetail({
          variables: {
            packageId: Number(getUserData.getUser.result.packageID),
          },
        });
      }
    }
  }, [getUserData, fetchPackageDetail]);

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    const pathName = props.location.pathname;

    new MetisMenu('#side-menu');
    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [props.location.pathname, activateParentDropdown]);

  useEffect(() => {
    if (localStorage.getItem('authUser')) {
      const obj = JSON.parse(localStorage.getItem('authUser'));
      if (process.env.REACT_APP_DEFAULTAUTH === 'firebase') {
        setcompPercent(obj.compPercent);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === 'fake' ||
        process.env.REACT_APP_DEFAULTAUTH === 'jwt'
      ) {
        setcompPercent(obj.compPercent);
        setUserProfileData(obj);
      }
    }
  }, [props.success, props]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar
        style={{ maxHeight: '100%' }}
        ref={ref}
        className="sidebar-menu-scroll"
      >
        <div className="p-3">
          <h5 className="font-size-14 mb-3 mt-0">Profile Progress</h5>

          <Slider value={60} orientation="horizontal" />
          {/* {compPercent} */}
        </div>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="menu-title">{props.t('Menu')} </li>
            <li>
              <Link to="/#" className="waves-effect">
                <i className="uil-home-alt"></i>
                <span className="badge rounded-pill bg-primary float-end">
                  New
                </span>
                <span>{props.t('Dashboard')}</span>
              </Link>
            </li>

            <li className="menu-title">{props.t('Actions')}</li>
            {/*<Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/calendar" className=" waves-effect">
                    <i className="uil-calender"></i>
                    <span>{props.t('Calendar')}</span>
                  </Link>
                </li>
              )}
            </Row>*/}

            {/* <li>
              <Link to="/chat" className="waves-effect">
                <i className="uil-comments-alt"></i>
                <span className="badge rounded-pill bg-warning float-end">
                  {props.t("New")}
                </span>
                <span>{props.t("Chat")}</span>
              </Link>
            </li> */}
            <li>
              <Link to="/sendrequest" className="waves-effect">
                <i className="uil-edit"></i>

                <span>{props.t("Send a Request")}</span>                
              </Link>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-store"></i>
                <span>{props.t('Ecommerce')}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ecommerce-products">{props.t('Products')}</Link>
                </li>
                {/* <li>
                  <Link to="/myproduct">{props.t("My Product ")}</Link>
                </li> */}
                <li>
                  <Link to="/ecommerce-orders">{props.t('Orders')}</Link>
                </li>
                {/* <li>
                  <Link to="/ecommerce-customers">{props.t("Customers")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/ecommerce-cart">{props.t("Cart")}</Link>
                </li> */}
                <li>
                  <Link to="/ecommerce-checkout">{props.t('Checkout')}</Link>
                </li>
                {/* <li>
                  <Link to="/ecommerce-shops">{props.t("Shops")}</Link>
                </li> */}
               {/* <li>
                  <Link to="/ecommerce-add-product">
                    {props.t('Add Product')}
                  </Link>
                </li>*/}
              </ul>
            </li>
           
            <li> 
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-store"></i>
                <span>{props.t("My Shop")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ecommerce-products">{props.t("My Products")}</Link>
                </li>
                {/* <li>
                  <Link to="/myproduct">{props.t("My Product ")}</Link>
                </li> */}
                <li>
                  <Link to="/ecommerce-add-product">
                    {props.t("Add Product")}
                  </Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Customer Orders")}</Link>
                </li>
                <li>
                  <Link to="/calendar">{props.t("Hired Orders")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Customer Bids")}</Link>
                </li>
                {/* <li>
                  <Link to="/ecommerce-customers">{props.t("Customers")}</Link>
                </li> */}
                {/* <li>
                  <Link to="/ecommerce-cart">{props.t("Cart")}</Link>
                </li> 
                <li>
                  <Link to="/ecommerce-checkout">{props.t("Checkout")}</Link>
                </li>*/}
                {/* <li>
                  <Link to="/ecommerce-shops">{props.t("Shops")}</Link>
                </li> */}
            
            </ul>
              </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-store"></i>
                <span>{props.t("My Content")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ecommerce-products">{props.t("Jobs")}</Link>
                </li>               
                <li>
                  <Link to="/ecommerce-orders">{props.t("Tenders")}</Link>
                </li>
                <li>
                  <Link to="/calendar">{props.t("Events")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Industry Trends")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Blogs")}</Link>
                </li>
                
              </ul>
            </li>
            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-store"></i>
                <span>{props.t("Content/Jobs etc")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ecommerce-products">{props.t("Jobs")}</Link>
                </li>               
                <li>
                  <Link to="/ecommerce-orders">{props.t("Tenders")}</Link>
                </li>
                <li>
                  <Link to="/calendar">{props.t("Events")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Industry Trends")}</Link>
                </li>
                <li>
                  <Link to="/ecommerce-orders">{props.t("Blogs")}</Link>
                </li>
                
              </ul>
            </li>
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/lead" className="waves-effect">
                    <i className="uil-cell"></i>
                    <span>{props.t('Leads')}</span>
                  </Link>
                </li>
              )}
            </Row>
            <Row>
              <li>
                <Link to="/businessenquiry" className="waves-effect">
                  <i className="uil-comment-message"></i>
                  <span>{props.t('Business Enquiry')}</span>
                </Link>
              </li>
            </Row>
            {/* <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="uil-envelope"></i>
                    <span>{props.t("Email")}</span>
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/email-inbox">{props.t("Inbox")}</Link>
                    </li>
                    <li>
                      <Link to="/email-read">{props.t("Read Email")} </Link>
                    </li>
                  </ul>
                </li>
              )}
            </Row> */}

            <li>
              <Link to="/special" className="waves-effect">
                <i className="uil-medal"></i>
                <span>{props.t('View Specials')}</span>
              </Link>
            </li>

            <li>
              <Link to="/catalogue" className="waves-effect">
                <i className="uil-layer-group"></i>
                <span>{props.t('View eCatalogues')}</span>
              </Link>
            </li>
            <Row>
              <li>
                <Link to="/reviews" className="waves-effect">
                  <i className="uil-favorite"></i>
                  <span>{props.t('Rating & Reviews')}</span>
                </Link>
              </li>
            </Row>
            {mainBusinessUser === true && (
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="uil-invoice"></i>
                  <span>{props.t('Invoices')}</span>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <Link to="/invoices-list">{props.t('Invoice List')}</Link>
                  </li>
                  <li>
                    <Link to="/invoices-detail">
                      {props.t('Invoice Detail')}
                    </Link>
                  </li>
                </ul>
              </li>
            )}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-book-alt"></i>
                <span>{props.t("Contacts")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/contacts-grid">{props.t("User Grid")}</Link>
                </li>
                <li>
                  <Link to="/contacts-list">{props.t("User List")}</Link>
                </li>
                <li>
                  <Link to="/contacts-profile">{props.t("Profile")}</Link>
                </li>
              </ul>
            </li> */}

            <li className="menu-title">Activities</li>
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/companyProfile" className="waves-effect">
                    <i className="uil-user-square"></i>
                    <span>{props.t('Company Profile')}</span>
                  </Link>
                </li>
              )}
            </Row>
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/myspecial" className="waves-effect">
                    <i className="uil-no-entry"></i>
                    <span>{props.t('My Special')}</span>
                  </Link>
                </li>
              )}
            </Row>
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/myecatalogue" className="waves-effect">
                    <i className="uil-book-open"></i>
                    <span>{props.t('My eCatalogues')}</span>
                  </Link>
                </li>
              )}
            </Row>
            {mainBusinessUser === true && (
              <li>
                <Link to="/myrequest" className="waves-effect">
                  <i className="uil-edit"></i>
                  <span>{props.t('My Request')}</span>
                </Link>
              </li>
            )}
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/incomingbusinessenquiry" className="waves-effect">
                    <i className="uil-comment-alt-message"></i>
                    <span>{props.t('Incoming Customer Enquiry')}</span>
                  </Link>
                </li>
              )}
            </Row>

            <li>
              <Link to="/myfavourite" className="waves-effect">
                <i className="uil-heart-alt"></i>
                <span>{props.t('My Favourite')}</span>
              </Link>
            </li>

            <li>
              <Link to="/myreviews" className="waves-effect">
                <i className="uil-thumbs-up"></i>
                <span>{props.t('My Ratings & Reviews')}</span>
              </Link>
            </li>
            <Row>
              {mainBusinessUser === true && (
                <li className="menu-title">{props.t('CRM')}</li>
              )}
            </Row>
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/customer" className="waves-effect">
                    <i className="uil-users-alt"></i>
                    <span>{props.t('Customer')}</span>
                  </Link>
                </li>
              )}
            </Row>

            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/inventory" className="waves-effect">
                    <i className="uil-vector-square"></i>
                    <span>{props.t('Inventory')}</span>
                  </Link>
                </li>
              )}
            </Row>

            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/quotes" className="waves-effect">
                    <i className="uil-telegram-alt"></i>
                    <span>{props.t('Quotes')}</span>
                  </Link>
                </li>
              )}
            </Row>
            <Row>
              {mainBusinessUser === true && (
                <li>
                  <Link to="/settingpage" className="waves-effect">
                    <i className="uil-cog"></i>
                    <span>{props.t('SettingPage')}</span>
                  </Link>
                </li>
              )}
            </Row>
            <br />
            <br />
            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-flask"></i>
                <span>{props.t("UI Elements")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/ui-alerts">{props.t("Alerts")}</Link>
                </li>
                <li>
                  <Link to="/ui-buttons">{props.t("Buttons")}</Link>
                </li>
                <li>
                  <Link to="/ui-cards">{props.t("Cards")}</Link>
                </li>
                <li>
                  <Link to="/ui-carousel">{props.t("Carousel")}</Link>
                </li>
                <li>
                  <Link to="/ui-dropdowns">{props.t("Dropdowns")}</Link>
                </li>
                <li>
                  <Link to="/ui-grid">{props.t("Grid")}</Link>
                </li>
                <li>
                  <Link to="/ui-images">{props.t("Images")}</Link>
                </li>
                <li>
                  <Link to="/ui-lightbox">{props.t("Lightbox")}</Link>
                </li>
                <li>
                  <Link to="/ui-modals">{props.t("Modals")}</Link>
                </li>
                <li>
                  <Link to="/ui-rangeslider">{props.t("Range Slider")}</Link>
                </li>
                <li>
                  <Link to="/ui-session-timeout">
                    {props.t("Session Timeout")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-progressbars">{props.t("Progress Bars")}</Link>
                </li>
                <li>
                  <Link to="/ui-sweet-alert">{props.t("Sweet-Alert")}</Link>
                </li>
                <li>
                  <Link to="/ui-tabs-accordions">
                    {props.t("Tabs & Accordions")}
                  </Link>
                </li>
                <li>
                  <Link to="/ui-typography">{props.t("Typography")}</Link>
                </li>
                <li>
                  <Link to="/ui-video">{props.t("Video")}</Link>
                </li>
                <li>
                  <Link to="/ui-general">{props.t("General")}</Link>
                </li>
                <li>
                  <Link to="/ui-colors">{props.t("Colors")}</Link>
                </li>
                <li>
                  <Link to="/ui-rating">{props.t("Rating")}</Link>
                </li>
                <li>
                  <Link to="/ui-notifications">{props.t("Notifications")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="waves-effect">
                <i className="uil-shutter-alt"></i>
                <span className="badge rounded-pill bg-info float-end">6</span>
                <span>{props.t("Forms")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/basic-elements">{props.t("Basic Elements")}</Link>
                </li>
                <li>
                  <Link to="/form-validation">
                    {props.t("Form Validation")}
                  </Link>
                </li>
                <li>
                  <Link to="/form-advanced">{props.t("Form Advanced")}</Link>
                </li>
                <li>
                  <Link to="/form-editors">{props.t("Form Editors")}</Link>
                </li>
                <li>
                  <Link to="/form-uploads">{props.t("Form File Upload")} </Link>
                </li>
                <li>
                  <Link to="/form-xeditable">{props.t("Form Xeditable")}</Link>
                </li>
                <li>
                  <Link to="/form-repeater">{props.t("Form Repeater")}</Link>
                </li>
                <li>
                  <Link to="/form-wizard">{props.t("Form Wizard")}</Link>
                </li>
                <li>
                  <Link to="/form-mask">{props.t("Form Mask")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-list-ul"></i>
                <span>{props.t("Tables")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/tables-basic">{props.t("Basic Table")}</Link>
                </li>
                <li>
                  <Link to="/tables-datatable">{props.t("Data Table")}</Link>
                </li>
                <li>
                  <Link to="/tables-responsive">
                    {props.t("Responsive Table")}
                  </Link>
                </li>
                <li>
                  <Link to="/tables-editable">{props.t("Editable Table")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-chart"></i>
                <span>{props.t("Charts")}</span>
              </Link>

              <ul className="sub-menu">
                <li>
                  <Link to="/apex-charts">{props.t("Apex")}</Link>
                </li>
                <li>
                  <Link to="/chartjs-charts">{props.t("Chartjs")}</Link>
                </li>
                <li>
                  <Link to="/e-charts">{props.t("E Chart")}</Link>
                </li>
                <li>
                  <Link to="/charts-knob">{props.t("Jquery Knob")}</Link>
                </li>
                <li>
                  <Link to="/sparkline-charts">
                    {props.t("Sparkline Chart")}
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-streering"></i>
                <span>{props.t("Icons")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/icons-unicons">{props.t("Unicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-boxicons">{props.t("Boxicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-materialdesign">
                    {props.t("Material Design")}
                  </Link>
                </li>
                <li>
                  <Link to="/icons-dripicons">{props.t("Dripicons")}</Link>
                </li>
                <li>
                  <Link to="/icons-fontawesome">{props.t("Font awesome")}</Link>
                </li>
              </ul>
            </li>

            <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-location-point"></i>
                <span>{props.t("Maps")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/maps-google">{props.t("Google Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-vector">{props.t("Vector Maps")}</Link>
                </li>
                <li>
                  <Link to="/maps-leaflet">{props.t("Leaflet Maps")}</Link>
                </li>
              </ul>
            </li> */}

            {/* <li>
              <Link to="/#" className="has-arrow waves-effect">
                <i className="uil-share-alt"></i>
                <span>{props.t("Multi Level")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/#">{props.t("Level 1.1")}</Link>
                </li>
                <li>
                  <Link to="/#" className="has-arrow">
                    {props.t("Level 1.2")}
                  </Link>
                  <ul className="sub-menu">
                    <li>
                      <Link to="/#">{props.t("Level 2.1")}</Link>
                    </li>
                    <li>
                      <Link to="/#">{props.t("Level 2.2")}</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
