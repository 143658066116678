import gql from 'graphql-tag';

/* get getMstCategoryMain query */
const getPrdType = gql`
  query {
    getPrdType{ 
        count,
        currentPage,
        message,
        nextPage,
        prevPage,
        success,
        totalPages,
        result{
          typeID,
          typeName
        }
      }
      
    }
`;

export default getPrdType;
