import gql from 'graphql-tag';

/* get getMstCategoryMain query */
const getPrdSalesType = gql`
  query {
    getPrdSalesType{ 
      count,
      currentPage,
      message,
      nextPage,
      prevPage,
      success,
      totalPages,
      result{
        salesTypeID,
        salesTypeName
      }
    }
  }
`;

export default getPrdSalesType;
